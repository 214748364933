.block-document {
  padding: 20px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.block-document img {
  margin-right: 15px;
}
.block-document .document-name {
  width: 60%;
  max-width: 75%;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
}
.block-document .document-name span {
  font-size: 14px;
  font-weight: 500;
  color: #264576;
  /*&:hover {
				color: #4b6aa0;
			}*/
}
.block-document .download-btn {
  color: #4b6aa0;
  background-color: transparent;
  box-shadow: 0 4px 7px rgba(100, 177, 252, 0.3);
  border-radius: 25px;
  font-size: 14px;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  margin-right: 2rem;
  margin-top: 5px;
  cursor: pointer;
}
.block-document .download-btn:hover {
  color: #fff;
  background-color: #4b6aa0;
}
@media screen and (max-width: 1024px) {
  .block-document .document-name {
    width: 100%;
    max-width: 70%;
    display: inline-block;
    vertical-align: middle;
  }
  .block-document a {
    margin-left: auto;
  }
  .block-document .download-btn {
    margin-right: 0;
  }
}
@media screen and (max-width: 900px) {
  .block-document .document-name {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 768px) {
  .block-document .document-name {
    max-width: 80%;
  }
}
@media screen and (max-width: 480px) {
  .block-document .document-name {
    margin-bottom: 16px;
  }
  .block-document .download-btn {
    color: #4b6aa0;
    background-color: #fff;
    box-shadow: 0 4px 7px rgba(100, 177, 252, 0.3);
    border-radius: 25px;
    font-weight: bold;
    padding: 0.5rem 0.75rem;
    display: block;
    max-width: 100px;
    float: none;
    cursor: pointer;
  }
}

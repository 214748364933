.block-map {
  background-color: #e7ebf3;
  font-size: 14px;
  margin-bottom: 40px;
  border-radius: 16px;
}
.block-map .col-md-5 {
  background-color: #fff;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
}
.block-map .contacts-right-block {
  padding: 30px 10px;
}
.block-map .contacts-right-block p {
  color: #3e485a;
  margin-bottom: 20px;
  font-weight: 500;
}
.block-map .map-left-block {
  padding-right: 0px;
  padding-left: 0px;
}
.name {
  font-weight: 900;
  margin-bottom: 0px;
}
.structure {
  margin-bottom: 50px;
}
.structure p {
  color: #3e485a;
  margin-bottom: 20px;
  font-weight: 500;
}
.structure h2 {
  color: #647799;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 30px;
}
.structure .fio {
  font-size: 15px;
  margin: 0;
  color: #3e485a;
  margin-bottom: 5px;
}
.structure .position {
  color: #484848;
  font-size: 10px;
  margin: 0;
  text-transform: uppercase;
}
.structure .nav-pills .nav-link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #264576;
  background: #ebf3ff;
  box-shadow: 0px 4px 7px rgba(100, 177, 252, 0.3);
  margin-bottom: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.structure .nav-pills .nav-link.active {
  background-color: #fff !important;
}
.structure .nav-pills .nav-link.active,
.structure .nav-pills .show > .nav-link {
  color: #3e485a;
  background-color: #d7e0eb;
}
.structure h3 {
  font-size: 26px;
  color: #3e485a;
  font-weight: normal;
  margin-bottom: 10px;
}
.structure .position-right-col {
  font-size: 11px;
  color: #484848;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.text-underline a {
  display: inline-block;
  border-bottom: 1px dotted #3e485a;
}
.text-underline-only a {
  border-bottom: 1px dotted #3e485a;
}
.text-underline-only img {
  display: inline;
  margin: 10px 20px 0 0;
  float: left;
  border-radius: 16px;
}
@media (max-width: 420px) {
  .tab-content {
    margin-top: 20px;
    padding: 0 16px;
  }
  .tab-content img {
    margin-bottom: 15px;
  }
  .tab-content .row {
    margin-left: 0;
    margin-right: 0;
  }
  .text-underline-only img {
    display: inline;
    margin: 10px 0 10px 0;
    float: left;
    border-radius: 16px;
    width: 100%!important;
    max-width: 420px;
  }
}

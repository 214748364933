html,
body {
  height: 100%;
  margin: 0;
}
#root {
  height: 100%;
}
.main-content-start-page {
  background: linear-gradient(107.51deg, #12348d 0.21%, #0e2662 99.57%);
  padding: 40px 0;
  height: 100%;
  margin-bottom: auto;
  min-width: 1200px;
  overflow: auto;
}
.startPageMap {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  max-width: 1500px;
  left: 50%;
  transform: translate(-50%, 0);
}
.startPageMap__language {
  position: absolute;
  top: -20px;
  right: 55px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: space-between;
  width: 100%;
  max-width: 70px;
  font-size: 14px;
  padding: 5px;
}
.startPageMap__language .ru-lang,
.startPageMap__language .en-lang {
  cursor: pointer;
  color: #fff;
}
.startPageMap__language .active {
  font-weight: 700;
  font-size: 16px;
}
.startPageMap__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 60px;
  margin-bottom: 30px;
}
.startPageMap__header__logo {
  max-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
}
.startPageMap__header__name {
  font-family: "Capital Serif", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #fff;
}
.startPageMap__btn {
  background-color: #8291b7;
  box-shadow: 0px 1px 4px rgba(32, 35, 51, 0.3), 0px 4px 8px rgba(32, 35, 51, 0.3);
  border-radius: 100px;
  width: 180px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  padding: 10px 20px;
  margin-right: 15px;
  margin-top: 25px;
}
.startPageMap__btn:active,
.startPageMap__btn:hover,
.startPageMap__btn:focus {
  color: #ffffff;
  background-color: #2d62ee;
}
.startPageMap__container {
  height: 100%;
}
.startPageMap__container path:hover {
  fill: #2d62ee;
}
.startPageMap__container {
  position: relative;
}
.startPageMap__container .ymaps-2-1-79-balloon__layout {
  background-color: transparent;
}
.startPageMap__container .ymaps-2-1-79-balloon__content {
  background-color: transparent;
  padding: 0;
  overflow: hidden;
  width: auto;
}
.startPageMap__container .ymaps-2-1-79-balloon__close + .ymaps-2-1-79-balloon__content {
  margin-right: 0;
}
.startPageMap__container .ymaps-2-1-79-balloon__tail:after {
  width: 0;
  height: 0;
}
.startPageMap__container .ymaps-2-1-79-balloon__tail {
  display: none;
}
.startPageMap__mediaPreview_block {
  flex-direction: row;
  position: relative;
  display: flex;
  width: 100%;
}
.startPageMap__mediaPreview_btn {
  width: 39.87px;
  height: 130px;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 16px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  cursor: pointer;
}
.startPageMap__mediaPreview_about {
  width: 460px;
  height: 130px;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
}
.startPageMap__mediaPreview_about_imgBlock {
  width: 150px;
  height: 130px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 16px 0 0 16px;
}
.startPageMap__mediaPreview_about_textBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
}
.startPageMap__mediaPreview_about_textBlock-region {
  font-family: Roboto;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #4a4a51;
  margin-bottom: 4px;
}
.startPageMap__mediaPreview_about_textBlock-name {
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4a4a51;
  width: 264px;
}
.startPageMap__mediaPreview_about_arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 10px;
  width: 26px;
  cursor: pointer;
}
.startPageMap__mediaPreview .pin_round {
  width: 16px;
  height: 16px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12), 0px 0px 12px #ffffff;
  border-radius: 100px;
  position: absolute;
  top: 63%;
  left: 49%;
  cursor: pointer;
}
.startPageMap__mediaPreview .pin_round:hover,
.startPageMap__mediaPreview .pin_round.active {
  border: 6px solid rgba(0, 255, 240, 0.3);
  width: 18px;
  height: 18px;
  background: #ffffff;
}
.startPageMap__mediaPreview .pin_round_small {
  width: 8px;
  height: 8px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12), 0px 0px 12px #ffffff;
  border-radius: 100px;
  position: absolute;
  top: 40%;
  left: 48%;
  cursor: pointer;
  border: 0;
}
.startPageMap__mediaPreview .pin_round_small:hover {
  border: 8px solid rgba(0, 255, 240, 0.3);
  width: 28px;
  height: 28px;
  background: #ffffff;
}
.startPageMap__mediaPreview_about_imgBlock {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 16px 0 0 16px;
}
.startPageMap__footer {
  margin-top: 58px;
  display: flex;
  justify-content: space-between;
}
.startPageMap__footer-social-vk {
  width: 30px;
  height: 40px;
  left: 40px;
  top: 1000px;
  background: #8291b7;
  box-shadow: 0px 1px 4px rgba(32, 35, 51, 0.3), 0px 4px 8px rgba(32, 35, 51, 0.3);
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.startPageMap__footer-social-vk:hover {
  background: #2d62ee;
}
.ymaps-2-1-79-map-copyrights-promo,
.ymaps-2-1-79-copyright {
  height: 0px;
}
.ymaps-2-1-79-copyrights-pane {
  display: none;
}
.zoomControl {
  bottom: 30px;
  top: auto;
  right: 12%;
  left: auto;
  box-shadow: 0px 1px 4px rgba(32, 35, 51, 0.3);
  border-radius: 100px;
  line-height: 0;
  background: #8291b7;
  z-index: 999;
}
.zoomControl .btnZoomControl {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  width: 30px;
  height: 60px;
  background: #8291b7;
  outline: none;
}
.zoomControl .btnZoomControl:hover {
  background: rgba(255, 255, 255, 0.16);
}
.zoomControl .btnPlus {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  border-radius: 100px 100px 0 0;
}
.zoomControl .btnMinus {
  font-size: 50px;
  color: #fff;
  border-radius: 0 0 100px 100px;
}
.zoomControl img {
  user-select: none;
}
ymaps[id^="id_"] {
  min-width: 500px;
}
.ymaps-2-1-79-balloon {
  box-shadow: none !important;
}
.modal__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.19);
  backdrop-filter: blur(6px);
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  z-index: 100;
}
.modal__align {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.modal__content {
  width: 800px;
  height: 500px;
  box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07), 0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112), 0px 7.779px 7.30492px rgba(0, 0, 0, 0.035), 0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
  border-radius: 20px;
  background: transparent;
  color: #000;
  margin: 0rem 4rem;
}
.modal__close {
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  bottom: 50px;
  width: 32px;
  height: 32px;
  padding: 0;
}
.modal__video-align {
  display: flex;
  position: relative;
  bottom: 37px;
}
.modal__video-style {
  border-radius: 20px;
  z-index: 100;
}
.modal__spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal__spinner {
  animation: spin 2s linear infinite;
  font-size: 40px;
  color: #1b6aae;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1540px) {
  .main-content-start-page {
    width: 100%;
    min-width: auto;
  }
}
@media screen and (max-width: 855px) {
  .startPageMap__btn {
    width: 200px;
    max-width: 200px;
  }
}
@media screen and (max-width: 800px) {
  .modal__content {
    margin: 0 1rem;
    width: 100%;
  }
  .modal__video-style {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .main-content-start-page {
    padding: 19px 0;
  }
  .startPageMap__header {
    padding: 0 16px;
  }
  .startPageMap__footer {
    padding: 0 16px;
  }
  .startPageMap__header__name {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #8291b7;
    max-width: 370px;
  }
  .startPageMap__btn {
    width: 100%;
    max-width: 146px;
    height: 28px;
    font-size: 14px;
    padding: 6px 12px;
    align-self: center;
    margin-top: 25px;
  }
  .startPageMap__language {
    top: -10px;
    font-size: 11px;
    max-width: 60px;
  }
  .startPageMap__language .active {
    font-size: 14px;
  }
  .startPageMap__header__logo div:not(:first-child) {
    margin-top: 20px !important;
  }
  .startPageMap__header__logo div img {
    max-width: 80px !important;
  }
}
@media screen and (max-width: 576px) {
  .startPageMap__header {
    flex-direction: column;
  }
  .startPageMap__header__logo {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-left: 0;
  }
  .startPageMap__header__name {
    width: 100%;
    margin: 50px auto 20px auto;
  }
  .startPageMap__btn {
    position: absolute;
    top: 215px;
    right: 15px;
  }
}
@media screen and (max-width: 499px) {
  .modal__content {
    background: transparent;
    height: auto;
  }
  .modal__video-align {
    bottom: 0px;
  }
  .modal__video-style {
    height: auto;
  }
}
@media screen and (max-width: 375px) {
  .main-content-start-page {
    padding: 5px 0 16px 0;
  }
  .startPageMap__header {
    flex-wrap: wrap;
    height: auto;
  }
  .startPageMap__header__name {
    margin-top: 28px;
  }
  .startPageMap__header__logo {
    flex-direction: row !important;
  }
  .startPageMap__header__logo div img {
    width: 70px !important;
    height: 70px !important;
  }
  .startPageMap__header__logo div:not(:first-child) {
    margin-top: 0px !important;
  }
  .startPageMap__language {
    top: -5px;
    right: 20px;
    font-size: 11px;
    max-width: 60px;
  }
  .startPageMap__language .active {
    font-size: 14px;
  }
  .startPageMap__btn {
    max-width: auto;
    top: 165px;
    right: 5px;
  }
  .ymaps-2-1-79-balloon-pane {
    z-index: 200000!important;
  }
  .startPageMap__mediaPreview_about_textBlock-name {
    font-size: 13px;
  }
}
@media screen and (max-width: 320px) {
}
@media screen and (max-width: 280px) {
  .startPageMap__btn {
    top: 195px;
    right: 5px;
  }
  .startPageMap__mediaPreview_block {
    flex-direction: column;
    position: relative;
  }
  .startPageMap__mediaPreview_btn {
    height: 40px;
    margin: 10px;
    position: absolute;
    top: 80px;
  }
  .startPageMap__mediaPreview_about_imgBlock {
    width: 80px;
    height: 80px;
    border-radius: 0;
    margin-left: 5px;
    margin-top: 5px;
  }
  .startPageMap__container .ymaps-2-1-79-balloon_layout_panel {
    background: rgba(255, 255, 255, 0.75);
  }
  .startPageMap__mediaPreview_about {
    background: transparent;
    max-width: 280px;
    width: 100%;
  }
  .startPageMap__mediaPreview_about_textBlock {
    max-width: 155px;
    width: 100%;
    padding: 0 0 5px 5px;
    margin-top: 5px;
  }
  .startPageMap__mediaPreview_about_textBlock-name {
    width: auto;
  }
  ymaps[id^="id_"] {
    height: 180px!important;
  }
}

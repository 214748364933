.main-page-map .content {
  display: flex;
  align-items: flex-start;
  padding: 0;
  flex-direction: row;
}
.main-page-map .left-menu ul {
  list-style-type: none;
  border: none;
}
.main-page-map .left-menu li:not(:last-child) {
  border-bottom: none;
}
.main-page-map .left-menu a {
  display: block;
  border: 1px solid transparent;
  background-color: #EBF3FF;
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #264576;
  cursor: pointer;
}
.main-page-map .left-menu a .info {
  padding: 0;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}
.main-page-map .left-menu a .info p {
  margin-bottom: 0;
  margin-top: 10px;
}
.main-page-map a {
  text-decoration: none;
}
.main-page-map .left-menu {
  flex-shrink: 0;
  width: 35%;
  max-height: 516px;
  overflow-y: auto;
}
.main-page-map .map {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 20px;
}
.main-page-map .breadcrumbs {
  margin-left: 0;
  margin-bottom: 40px;
}
.main-page-map .breadcrumbs .map-tab-link {
  font-family: 'Heuristica', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #264576;
  cursor: pointer;
}
.main-page-map .breadcrumbs .map-tab-link.active,
.main-page-map .breadcrumbs .map-tab-link:hover {
  background-color: transparent;
  transition: all 0.15s;
  color: #000;
}
.main-page-map .map svg {
  display: block;
  width: 100%;
  height: auto;
  transition: all 0.15s;
}
.main-page-map .map a {
  transition: all 0.15s;
}
.main-page-map .map-tab-link.active {
  background-color: #667B9B;
  transition: all 0.15s;
  color: #fff;
}
.main-page-map .map-tab-link.active .info p {
  color: #fff;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #667B9B;
  border-radius: 0.25rem;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #667B9B;
}
.tooltip.show {
  opacity: 1;
}
@media (max-width: 1124px) {
  .main-page-map .content {
    flex-direction: column-reverse;
    align-items: center;
  }
  .main-page-map .left-menu {
    flex-shrink: 0;
    width: 90%;
  }
  .main-page-map .map .breadcrumbs {
    margin-bottom: 10px;
  }
  .main-page-map .map svg {
    margin-bottom: 20px;
  }
}
@media (max-width: 991px) {
  .container {
    padding-right: 0px;
    padding-left: 0px;
    max-width: 991px;
  }
  #regions_container {
    padding: 10px;
  }
  .scroll_statistic_table {
    height: calc(100vh - 200px);
  }
}

.top-tools {
  background-color: #fff;
  padding: 0 0.5rem;
  padding-right: 0px;
  padding-left: 15px;
  margin: 0 -15px;
}
.top-tools .navbar-nav .nav-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #264576;
  padding: 10px;
}
.top-tools .navbar-nav .nav-link:first-child {
  padding-left: 0;
}
.top-tools .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: transparent;
}
.top-tools .navbar-toggler-icon {
  background-image: url(/public/images/ec70a8ea1a3ce45de7cf17a79e41d218.png);
  width: 24px;
  height: 18px;
}
.top-tools .navbar-toggler-icon-special {
  display: none;
}
.visually-impaired {
  background-color: #3e485a;
  width: 80px;
  padding: 6px;
  height: 36px;
  text-align: center;
  cursor: pointer;
}
.visually-impaired_none {
  display: none;
}
.visually-impaired a img {
  height: 80%!important;
}
.select-lang {
  display: flex;
  height: 100%;
  width: 120px;
  text-transform: uppercase;
}
.select-lang span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  margin-left: 7px;
  padding: 5px 10px;
  margin-right: 0;
  cursor: pointer;
}
.select-lang span.active,
.select-lang span:hover {
  background-color: #3e485a;
  color: #FFFFFF;
}
.log-in-account {
  background-color: #cbd9f3;
  width: 50px;
  padding: 10px;
  height: 100%;
  text-align: center;
  cursor: pointer;
}
.top-search {
  padding: 1.5rem 0.5rem;
}
.top-search .row {
  align-items: center;
}
.top-search .block-search {
  justify-content: flex-end;
  float: right;
  max-width: 400px;
  width: 100%;
}
.top-search .form-search {
  justify-content: flex-end;
  max-width: 400px;
  flex-wrap: nowrap;
}
.top-search .form-search [type=text],
.top-search .form-search textarea {
  padding: 3px 15px;
}
.top-search .form-search input {
  width: 85%;
}
.top-search .form-search .form-control {
  border: 1px solid transparent;
  background: #FFFFFF;
  border-radius: 20px 0 0 20px;
  margin: 0;
  height: 40px;
  margin-right: 0;
  box-shadow: 0px 4px 7px rgba(100, 177, 252, 0.3);
}
.top-search .form-search .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  clip-path: inset(-3px -3px -4px -3px);
}
.top-search .btn-light {
  background-color: #ffffff;
  border-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0 20px 20px 0;
  border-left: 1px solid transparent;
  height: calc(1.5em + 0.75rem + 4px);
  box-shadow: 0px 4px 7px rgba(100, 177, 252, 0.3);
}
.top-search .input-focused {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  clip-path: inset(-3px -3px -4px 3px);
}
.top-search .logo {
  display: flex;
  align-items: center;
  height: 55px;
}
.top-search .logo-header-desk {
  display: block;
  margin-right: 16px;
}
.top-search .logo-header-text {
  font-family: 'Heuristica';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #264576;
  max-width: 470px;
  display: flex;
  align-content: center;
}
::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #8791AA;
}
@media (max-width: 1024px) {
  .top-search .form-search input {
    width: 80%;
  }
}
@media (max-width: 991px) {
  .container {
    padding-right: 0px;
    padding-left: 0px;
  }
  .top-search .form-search {
    margin-top: 15px;
    width: 100%;
  }
  .top-search .logo {
    height: auto;
  }
  .top-tools .navbar-nav .nav-link:first-child {
    padding-left: 10px;
  }
}
.special .navbar-toggler-icon-special {
  display: inline-block;
}
.special .navbar-toggler-icon {
  display: none;
}

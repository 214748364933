.map_container {
    border-bottom: none;
    max-width: 320px;
    padding: 0 5px;
    margin: 0 auto;
}
#searchmap {
    max-width: 350px;
    width: 100%;
    height: 210px;
    cursor: pointer;
    overflow-x: auto;
}
.o1 {
    opacity: 1;
    transition: all 1s ease-in-out;
}
.o02 {
	opacity:0.3;
	transition: all 1s ease-in-out;
}
#small_map {
    position: fixed;
    padding-top:70px;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(62, 72, 90, 0.8);
}
#small_map svg {
    position: absolute;
    right: 15%;
}
[class^='zoom'] {
    transform-origin: 0 0;
    transition: all 1s ease-in-out;
    width: 100%;
    height: 100%;
}
.zoom0 {
	transform: scale(0.2);
	opacity:0.1;
}
.zoom1 {
	transform: scale(0.2);
	opacity:0.1;
}
.zoom2 {
    transform: scale(1);
    opacity: 1;
}
#maphint {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	padding: 5px 10px 2px 10px;
	font-size: 11px;
	background:rgba(255,255,255,0.9);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.2);
	color: #000;
	display: block;
	z-index:999999;
}
#regions_container {
    background: rgba(255,255,255,0.9);
    position: absolute;
    top: 70px;
    z-index: 2;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}
[class^='map01'] {
    right: 40%;
}
[class^='map02'] {
    right: 40%;
}
[class^='map03'] {
    right: 40%;
}
[class^='map04'] {
    right: 40%;
}
[class^='map05'] {
    right: 40%;
}
[class^='map06'] {
    right: 40%;
}
[class^='map07'] {
    right: 40%;
}
[class^='map08'] {
    right: 40%;
}
#small_map [class$='del'] {
    background: #c3c6c9;
    padding: 5px 8px 8px 8px;
    font-size: 10pt;
    text-align: right;
    border-bottom: solid 1px #ccc;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, .2);
}
#regions_container ol {
    padding: 10px 10px 10px 35px;
    text-align: left;
}
#regions_container li {
    font-size: 80%;
    line-height: 120%;
    margin: 4px 5px 4px 0;
}
#regions_container li span {
    border-bottom: dotted 1px #333;
}
#regions_container li span:hover {
    border-bottom: solid 1px #333;
    cursor: pointer;
    background: #e3e6e9;
}

@media (max-width: 1300px) {
    #small_map svg {
        position: absolute;
        right: 1%;
    }
    [class^='map08'] {
        right: 35%;
    }
}
@media (max-width: 768px) {
    #regions_container {
        background: rgba(255,255,255,0.9);
        position: absolute;
        top: 0;
        left: 0;
    }
}

.statistic-total {
  font-size: 18px;
  color: #6F789A;
  text-align: left;
  margin-bottom: 50px;
}
.statistic-total .numbers {
  background-color: #3E485A;
  padding: 6px 12px;
  color: #fff;
  margin: 0 10px;
  border-radius: 5px;
}
.statistic-total .date {
  margin: 0 5px;
}
.custom-select {
  max-width: 100%;
  padding: 0.375rem 2rem 0.375rem 0.75rem;
  display: inline-block;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgOCA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC43NTk3NjYgMC4zNzU0NDVMMS4wMDg5MyAwLjAzNzU5NzdMNy43NjIyNiAwLjAzNzU5NzdMNy45OTkzNiAwLjM2MzM3OUw0LjYwNDU5IDMuNjU3MzlINC4xMDU2NkwwLjc1OTc2NiAwLjM3NTQ0NVoiIGZpbGw9IiMyNjQ1NzYiLz4KPC9zdmc+Cg==") no-repeat right 0.75rem center / 8px 4px;
  border: 1px solid transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: 0px 4px 7px rgba(100, 177, 252, 0.3);
  border-radius: 20px;
  margin-bottom: 16px;
}
.district-select .custom-select {
  max-width: 92%;
}
.list-regions .single-record {
  width: 48%;
  display: inline-block;
  float: left;
  margin-right: 2%;
  padding: 5px 10px;
  cursor: pointer;
}
.list-regions .single-record .float-left {
  width: 85%;
}
.list-regions .single-record .float-right {
  color: #77849b;
  width: 15%;
}
.single-record.active {
  background-color: #fff;
  color: #264576;
  border-radius: 16px;
  box-shadow: 0px 4px 7px rgba(100, 177, 252, 0.3);
}
.single-record.active .float-right {
  color: #264576;
}
.block-pie-chart {
  background-color: #fff;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 40px;
}
.block-pie-chart .name-object {
  font-size: 18px;
  font-weight: bold;
  color: #3d4859;
}
.block-pie-chart__total {
  width: 200px;
  text-align: center;
  position: absolute;
  top: 60px;
  left: calc(50% - 100px);
}
.block-pie-chart__total-value {
  font-weight: bold;
  font-size: 28px;
}
.block-pie-chart__total-title {
  font-weight: 500;
  font-size: 12px;
  color: #131C2C;
}
#TableChart {
  font-size: 15px;
}
#TableChart .block-color {
  width: 30px;
  height: 30px;
  margin: 5px auto;
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
}
#TableChart .fiolet {
  background-color: #6D61B7;
}
#TableChart .white {
  background-color: #ffffff;
  border: 1px solid #D9D9D9;
}
#TableChart .blue {
  background-color: #4b6aa0;
}
#TableChart .green {
  background-color: #80b54b;
}
#TableChart .red {
  background-color: #c45c5c;
}
#TableChart .orange {
  background-color: #e69a48;
}
#TableChart .number {
  font-weight: bold;
  font-size: 15px;
  color: #77849b;
}
#TableChart .subcategory {
  color: #6a7fa5;
  font-size: 15px;
}
#TableChart .google-visualization-table-tr-head {
  background-color: transparent;
}
#TableChart .google-visualization-table-table td {
  border: solid transparent;
  border-width: 0 0 0 0;
  padding: 5px 0.35em;
  font-size: 15px;
}
#TableChart .google-visualization-table-table th {
  border: solid transparent;
  border-width: 0 0 0 0;
}
#TableChart .google-visualization-table-tr-odd {
  background-color: #fff;
}
@media all and (max-width: 820px) {
  .block-pie-chart__total {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 200px;
    text-align: center;
    position: absolute;
    top: 65px;
    left: calc(50% - 100px);
  }
  .block-pie-chart__total-value {
    font-weight: bold;
    font-size: 20px;
  }
  .block-pie-chart__total-title {
    font-weight: 500;
    font-size: 12px;
    color: #131C2C;
    width: 50px;
  }
}

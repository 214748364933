.footer-block {
  padding: 1.5rem 0.5rem;
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-block .logo-grd {
  padding-right: 5px;
  display: flex;
  margin-bottom: 0px;
  max-width: 467px;
  align-items: center;
}
.footer-block .logo-grd img {
  max-width: 148px;
  width: 100%;
  float: left;
  margin-right: 24px;
}
.footer-block .logo-grd .text-grd {
  ffont-family: 'Heuristica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #264576;
}
.footer-block .contacts h6 {
  font-family: 'Heuristica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #264576;
}
.footer-block .contacts p,
.footer-block .contacts a {
  font-family: 'Heuristica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  margin: 0;
  color: #264576;
}
.footer-block .ksnkn {
  display: block;
  margin: 0px auto;
}
@media (max-width: 1024px) {
  .footer-block .logo-grd {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .footer-block {
    text-align: center;
    flex-direction: column;
  }
  .footer-block .logo-grd {
    padding-right: 0px;
    display: flex;
    flex-wrap: wrap;
  }
  .footer-block .logo-grd img {
    max-width: 148px;
    float: none;
    display: block;
    margin: 0px auto 20px auto;
  }
  .footer-block .contacts {
    margin: 20px auto;
  }
}
@media only screen and (max-width: 360px) {
  .top-search .logo-header-mobile {
    display: block;
    margin: 0 auto 0 0;
    width: 90%;
    height: auto;
  }
  #special,
  #special .active {
    border-bottom-width: 3px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  #special .special-panel div {
    margin-left: 5px !important;
  }
  .footer-block .ksnkn {
    max-width: 200px;
  }
}

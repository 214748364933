.audio-player-wrapper {
  height: 0px;
}
.question {
  position: relative;
}
.question .tooltiptext {
  visibility: hidden;
  width: 530px;
  background-color: #fff;
  color: #3e485a;
  text-align: left;
  border-radius: 0;
  padding: 10px;
  position: absolute;
  z-index: 99999999;
  box-shadow: 0px 9px 51px 0px rgba(25, 33, 64, 0.61);
  top: 129%;
  right: -51%;
}
.question .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 90%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.question:hover .tooltiptext {
  visibility: visible;
}
[type=text],
td,
textarea,
th {
  margin-right: 5px;
  padding: 3px 6px;
}
.listSongs {
  margin-top: 20px;
}
.songElement {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  margin: 5px;
  text-align: left;
  outline: 0;
  padding: 16px 16px 16px 35px;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #264576;
}
.songElement:focus,
.songElement:active,
.songElement:hover,
.songElement.active {
  color: #264576;
  background-color: #EBF3FF;
  border: 1px solid #EBF3FF;
}
.songElement:focus div,
.songElement:active div,
.songElement:hover div,
.songElement.active div {
  color: #264576;
}
.nameSong {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #264576;
  margin-bottom: 20px;
  height: auto;
}
[type=range] {
  -webkit-appearance: none;
  margin: 6px 0;
  width: 100%;
}
.volumeControl {
  width: 100%;
}
input[type="range"] {
  appearance: slider-horizontal;
  color: #101010;
  cursor: default;
  padding: initial;
  border: initial;
  margin: 2px;
  height: 2px;
  background: rgba(25, 25, 25, 0.12);
  border-radius: 2px;
}
input {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: textfield;
  background-color: -internal-light-dark(#ffffff, #3b3b3b);
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(#767676, #c3c3c3);
  border-image: initial;
}
.btnPlay {
  width: 40px;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #fff;
  box-shadow: 0px 4px 7px rgba(100, 177, 252, 0.3);
  border-radius: 100px;
  border-radius: 25px;
  outline: 0;
}
.btnPlay svg {
  fill: #1E82FA;
  width: 14px;
  height: 14px;
}
button:focus {
  outline: 0;
}
@media (max-width: 768px) {
  .question .tooltiptext {
    width: calc(1000% - 40px);
  }
}

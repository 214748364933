.mapVideoModal__body {
  padding-bottom: 30px !important;
}
.mapVideoModal__footer {
  cursor: pointer;
  font-size: 18px;
  background-color: #fff;
  border-radius: 1.6rem;
  margin-top: 30px;
}
.mapVideoModal__footer-text {
  margin-left: auto;
}
.mapVideoModal__footer-icon {
  margin-left: auto;
}
.video-player-wrapper {
  width: 100%;
}
.video-player-wrapper video {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

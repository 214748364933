.right-col-card {
  background-color: #fff;
  color: #264576;
  padding: 30px;
  border-radius: 16px;
}
.right-col-card .sort-pagination {
  flex-wrap: wrap;
  width: 90%;
  margin: 15px auto;
  justify-content: flex-start;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.right-col-card .sort-objects .col-md-12:not(:last-child) .block-document {
  border-bottom: 1px solid #cfd7e5;
}
.block-document .document-name {
  width: 60%;
  max-width: 75%;
  display: flex;
  vertical-align: middle;
}
.block-document .document-name span:first-child {
  margin-bottom: 8px;
}
.block-document .document-name span:last-child {
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
}
.block-document .download-btn {
  color: #4b6aa0;
  background-color: transparent;
  box-shadow: 0 4px 7px rgba(100, 177, 252, 0.3);
  border-radius: 25px;
  font-size: 14px;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  margin-right: 2rem;
  margin-top: 5px;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  .row-column {
    flex-direction: column;
  }
}

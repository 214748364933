.mapAudioModal .modal .modal-dialog {
  max-width: 512px!important;
}
.mapAudioModal_title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500!important;
  font-size: 20px!important;
  line-height: 23px;
  color: #264576 !important;
}
.mapAudioModal__footer {
  cursor: pointer;
  font-size: 18px;
  background-color: #fff;
  border-radius: 1.6rem;
  margin-top: 30px;
}
.mapAudioModal__footer-text {
  margin-left: auto;
}
.mapAudioModal__footer-icon {
  margin-left: auto;
}
.modal .modal-dialog {
  max-width: 512px!important;
}
.btnPlay {
  width: 40px;
  height: 50px;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: 0px 4px 7px rgba(100, 177, 252, 0.3);
  border-radius: 100px;
  outline: 0;
}
.btnPlay svg {
  fill: #1E82FA;
  width: 14px;
  height: 14px;
}
button:focus {
  outline: 0;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.2rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.2rem;
}
.left-col-card .imgObg {
  height: auto;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 16px;
}
.left-col-card .left-nav-card {
  display: flex;
  flex-direction: column;
}
.left-col-card .left-nav-card img {
  margin-right: 10px;
}
.right-col-card {
  background-color: #fff;
  color: #264576;
  padding: 30px;
  border-radius: 16px;
}
.right-col-card .nknCard_subtitle-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}
.right-col-card .nknCard_subtitle-container .nknCard_subtitle-elem {
  width: 50%;
  padding-right: 30px;
  margin-bottom: 8px;
}
.right-col-card .nknCard_subtitle-container span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;
  color: #264576;
}
.right-col-card .nknCard_subtitle-container span.nknCard_subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #838489;
  display: block;
}
.right-col-card h2 {
  font-family: 'Heuristica';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #264576;
  margin-bottom: 60px;
}
.right-col-card .object-description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #264576;
}
.right-col-card p {
  margin-bottom: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #264576;
}
.right-col-card h3 {
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 30px;
}
.right-col-card hr {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid rgba(25, 25, 25, 0.16);
  margin-right: -30px;
  margin-left: -30px;
}
.right-col-card .obj-cards .obj-card {
  width: 30%;
  display: inline-block;
  float: left;
}
.right-col-card .obj-cards .obj-card img {
  margin-bottom: 15px;
}
.right-col-card .read-more {
  width: 164px;
  height: 43px;
  background: #FFFFFF;
  box-shadow: 0px 4px 7px rgba(100, 177, 252, 0.3);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #264576;
  cursor: pointer;
}
.right-col-card .read-more:hover {
  box-shadow: 1px 4px 7px rgba(100, 177, 252, 0.8);
  border: 1px solid rgba(100, 177, 252, 0.8);
}
.right-col-card .infoAccordion .card {
  border: 1px solid #e7ebf3;
  background-color: #EBF3FF;
  border-radius: 8px;
  margin-bottom: 4px;
}
.right-col-card .infoAccordion .card p {
  margin-bottom: 15px;
}
.right-col-card .infoAccordion .card .card-text,
.right-col-card .infoAccordion .card .card-body {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #264576;
}
.right-col-card .infoAccordion .card .card-body {
  background-color: #fff;
}
.right-col-card .infoAccordion .card-header {
  padding: 1.575rem 1.675rem 1.575rem 0.75rem;
  margin-bottom: 0;
  background-color: #e7ebf3;
  border-bottom: 1px solid #e7ebf3;
}
.right-col-card .infoAccordion .name-info {
  font-weight: bold;
  color: #3e485a;
  cursor: pointer;
}
.right-col-card .infoAccordion .name-info:after {
  content: url(/public/images/bafc9beb243e312fa43ac5f94c81ea05.svg);
  float: right;
  margin-left: 15px;
}
.right-col-card .infoAccordion .collapsedStyling {
  color: #4b6aa0;
}
.right-col-card .infoAccordion .collapsedStyling:after {
  content: url(/public/images/bafc9beb243e312fa43ac5f94c81ea05.svg);
  float: right;
  margin-left: 15px;
  -ms-transform: rotate(180deg);
  /* IE 9 */
  transform: rotate(180deg);
  /* Standard syntax */
}
.right-col-card .datesBlock .date {
  display: inline-block;
  float: right;
  margin: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #264576;
}
.right-col-card .tags .tag {
  display: inline-block;
  float: right;
  margin: 5px 10px;
  padding: 5px 10px;
}
.right-col-card .tags .nameTag {
  border: 1px solid #e7ebf3;
  border-radius: 0;
  color: #5b77a9;
  margin: 5px;
}
.right-col-card .slideBlock {
  background-color: #BCCAE0;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  border-radius: 16px;
}
.right-col-card .slideBlock .tooltiptext {
  visibility: hidden;
  width: 66%;
  background-color: #fff;
  color: #3e485a;
  text-align: left;
  border-radius: 0;
  padding: 10px;
  position: absolute;
  z-index: 99999999;
  bottom: 3%;
  right: 20%;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}
.right-col-card .slideBlock .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 90%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.right-col-card .slideBlock:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.right-col-card .slideBlock .imgSlide {
  height: 100%;
  margin: 0 auto;
  width: auto;
  display: block;
}
.right-col-card .link-popover {
  cursor: pointer;
}
.right-col-card .link-popover span {
  margin-right: 10px;
  color: #4b6aa0;
  font-weight: bold;
  text-decoration: underline;
}
.popover {
  border: 0;
  max-width: 370px;
  width: 100%;
  border-radius: 16px;
}
.popover .arrow {
  display: none;
}
.popover .popover-body {
  background-color: #FFFFFF;
  box-shadow: 0px 20px 20px rgba(50, 50, 71, 0.1), 0px 32px 40px rgba(50, 50, 71, 0.1);
  padding: 0;
  color: #3e485a;
  border-radius: 16px;
}
.popover .info {
  font-size: 13px;
}
.popover tr:nth-child(2) td {
  margin: 0 0 0 5px;
  padding: 0 3px 3px 10px;
  vertical-align: top;
}
.popover img {
  width: 100%;
  height: auto;
}
.popover .popover-body_container {
  display: flex;
  flex-direction: column;
}
.popover .popover-body_container .popover-body_container-img {
  border-radius: 16px 16px 0 0;
  height: 246px;
  background-position: center;
  background-size: contain;
}
.popover .popover-body_container .popover-body_container-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #264576;
  padding: 30px 20px 10px 20px;
}
.popover .popover-body_container .popover-body_container-descr {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #264576;
  padding: 0 20px 30px 20px;
}
.sliderMain .slideBlock {
  height: 460px;
}
.sliderMain .slick-prev {
  left: 25px;
  z-index: 99;
  width: 40px;
  height: 40px;
}
.sliderMain .slick-next {
  right: 25px;
  z-index: 99;
  width: 40px;
  height: 40px;
}
.sliderMain .slick-prev:before,
.sliderMain .slick-next:before {
  color: rgba(255, 255, 255, 0.9);
  font-size: 40px;
}
.sliderThumbs {
  background-color: transparent;
  padding: 10px;
}
.sliderThumbs .slideBlock {
  height: 80px;
  margin-right: 20px;
  background-position: top center;
  background-size: cover;
  cursor: pointer;
}
.slick-slider .slick-dots {
  bottom: 12px;
  display: block;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 80px;
}
.slick-slider .slick-dots li {
  width: 125px;
  height: 45px;
  display: inline-block;
  float: left;
}
.slick-slider .slick-dots li img {
  width: 100%;
}
.sliderThumbs .slick-current .slideBlock {
  border: 5px solid #9bbfff;
}
.info-block {
  background-color: transparent;
  padding: 10px;
}
.info-block .info-block-text {
  padding: 20px;
  height: auto;
  overflow: auto;
  background-color: #fff;
}
.info-block .info-block-text p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #264576;
  margin-bottom: 5px;
}
.info-block ::-webkit-scrollbar {
  width: 10px;
}
.info-block ::-webkit-scrollbar-thumb {
  background: #9abefe;
  border-radius: 10px;
}
.info-block ::-webkit-scrollbar-thumb:hover {
  background: #4b6aa0;
}
.thumbnail-slider-wrap .slick-list {
  height: 79px;
}
.object-description p {
  font-weight: normal;
}
@media (max-width: 767px) {
  .left-col-card .left-nav-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .left-col-card .left-nav-card span {
    display: none;
  }
  .left-col-card .left-nav-card img {
    margin-right: 0;
  }
  .right-col-card {
    padding: 15px;
    margin-right: -15px;
    margin-left: -15px;
  }
  .right-col-card hr {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (max-width: 768px) {
  .sliderMain .slideBlock {
    height: 280px;
  }
  .sliderThumbs .slideBlock {
    height: 50px;
    background-position: center;
  }
  .thumbnail-slider-wrap .slick-list {
    height: 50px;
  }
  .thumbnail-slider-wrap .slick-track {
    width: 90vw!important;
  }
  .thumbnail-slider-wrap .slick-slide {
    width: calc(90vw/5) !important;
  }
}
@media (max-width: 912px) {
  .right-col-card .nknCard_subtitle-container .nknCard_subtitle-elem {
    width: 90%;
  }
}

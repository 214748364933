.advanced-search h2 {
  margin-top: 20px;
}
.advanced-search .block-attribute-value {
  margin: 10px 0 20px 0;
}
.advanced-search .block-attribute-value .input-advanced-search .custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgOCA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC43NTk3NjYgMC4zNzU0NDVMMS4wMDg5MyAwLjAzNzU5NzdMNy43NjIyNiAwLjAzNzU5NzdMNy45OTkzNiAwLjM2MzM3OUw0LjYwNDU5IDMuNjU3MzlINC4xMDU2NkwwLjc1OTc2NiAwLjM3NTQ0NVoiIGZpbGw9IiMyNjQ1NzYiLz4KPC9zdmc+Cg==") no-repeat right 0.75rem center / 8px 4px;
  border: 1px solid transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: 0px 4px 7px rgba(100, 177, 252, 0.3);
  border-radius: 20px;
}
.advanced-search .block-attribute-value .input-advanced-search .form-group {
  margin-bottom: 10px;
}
.advanced-search .search-lens {
  background-color: #ffffff;
  border: 1px solid #3452c1;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0 3.25rem 3.25rem 0;
  border-left: 1px solid transparent;
  height: calc(1.5em + 0.75rem + 2px);
  width: 20%;
  position: relative;
  display: flex;
  justify-content: center;
}
.advanced-search .btn-filter {
  width: 100px;
  margin: 20px auto;
  color: #fff;
  background-color: #4b6aa0;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
}
.advanced-search .form-group {
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .right-block-search .form-search input {
    width: 80%;
  }
}

.main-block .left-col {
  padding: 0rem;
  background-color: transparent;
}
.main-block .left-col .about-block {
  padding: 0 0 1rem 0;
}
.main-block .left-col .about-block .random-card .text-card {
  cursor: pointer;
}
.main-block .left-col .about-block .random-card .text-card p {
  height: auto;
  max-height: 64px;
}
.main-block .right-col {
  padding: 0;
  background: #FFFFFF;
  border-radius: 16px;
}
.main-block h2 {
  font-family: 'Heuristica', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #264576;
}
.main-block p {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #264576;
  margin-bottom: 16px;
}
.main-block p b {
  font-family: 'Heuristica', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #264576;
}
.main-block img {
  margin-bottom: 15px;
}
.main-block hr {
  border: 1px solid rgba(25, 25, 25, 0.16);
  margin: 30px auto;
}
.main-block .map-main-page {
  position: relative;
  padding: 1.5rem 1.5rem 0 1.5rem;
  margin-bottom: 50px;
}
.main-block .map-main-page .btn-advanced-search {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 8px 16px;
  color: #3e485a;
  font-size: 13px;
  font-weight: 900;
  width: 228px;
  height: 40px;
  background: #FFFFFF;
  box-shadow: 0px 4px 7px rgba(100, 177, 252, 0.3);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.main-block .map-main-page .btn-advanced-search img {
  margin: 0px 0 0 18px;
}
.main-block .federal-district {
  padding: 1rem 0.5rem 1rem 1.5rem;
}
.main-block .federal-district .accordion-federal-district {
  padding: 1.5rem 0;
}
.main-block .federal-district .accordion-federal-district .card {
  border: 1px solid transparent;
  background-color: #EBF3FF;
  border-radius: 8px;
  margin-bottom: 4px;
}
.main-block .federal-district .accordion-federal-district .card .list-unstyled {
  padding-left: 20px;
}
.main-block .federal-district .accordion-federal-district .card-header {
  padding: 1.575rem 1.675rem 1.575rem 0.75rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: none;
  color: #4b6aa0;
  position: relative;
}
.main-block .federal-district .accordion-federal-district .name-info {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #264576;
  cursor: pointer;
}
.main-block .federal-district .accordion-federal-district .name-info:after {
  content: url(/public/images/bafc9beb243e312fa43ac5f94c81ea05.svg);
  position: absolute;
  top: 35%;
  right: 12px;
  margin: 0;
}
.main-block .federal-district .accordion-federal-district .collapsedStyling {
  color: #4b6aa0;
}
.main-block .federal-district .accordion-federal-district .collapsedStyling:after {
  content: url(/public/images/bafc9beb243e312fa43ac5f94c81ea05.svg);
  position: absolute;
  margin: 0;
  -ms-transform: rotate(180deg);
  /* IE 9 */
  transform: rotate(180deg);
  /* Standard syntax */
}
.main-block .federal-district .accordion-federal-district .card-body li {
  line-height: 1;
  margin-bottom: 10px;
}
.main-block .new-objects {
  padding: 0 0.5rem 0 1.5rem;
  margin-bottom: 0;
  height: auto;
  max-height: 632px;
  overflow-y: auto;
}
.main-block .new-objects .block-new-object {
  padding: 0 0.5rem;
  cursor: pointer;
  background: #EBF3FF;
  border: 0.5px solid #E2EDFF;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 8px;
}
.main-block .new-objects .block-new-object h5 {
  font-size: 15px;
  font-weight: bold;
  color: #3e485a;
}
.main-block .new-objects .block-new-object p {
  font-size: 13px;
  text-indent: 0;
  margin-bottom: 0;
}
.main-block .new-objects .block-new-object:hover h5 {
  color: #4b6aa0;
}
.main-block .new-objects .show-all-link {
  padding: 0 0.5rem;
  cursor: pointer;
  font-weight: bold;
  color: #4b6aa0;
}
.main-block ::-webkit-scrollbar {
  width: 4px;
}
.main-block ::-webkit-scrollbar-thumb {
  background: #264576;
  border-radius: 10px;
}
.main-block ::-webkit-scrollbar-thumb:hover {
  background: #667B9B;
}
.main-block .categories {
  background-color: #fff;
  height: auto;
  padding: 0 0.6rem 0.7rem 0.6rem;
  position: relative;
}
.main-block .categories h2 {
  margin-bottom: 0;
}
.main-block .categories .accordion-categories {
  padding: 0rem;
  width: 100%;
}
.main-block .categories .accordion-categories .card {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 0;
  cursor: pointer;
  border-bottom: 1px solid rgba(25, 25, 25, 0.16);
}
.main-block .categories .accordion-categories .card:hover .card-header {
  color: #4b6aa0;
}
.main-block .categories .accordion-categories .card:hover .card-body {
  color: #4b6aa0;
}
.main-block .categories .accordion-categories .card-body {
  padding: 1rem 0.85rem;
}
.main-block .categories .accordion-categories .card-body li {
  margin-bottom: 10px;
}
.main-block .categories .accordion-categories .card-body li a {
  color: #4b6aa0;
}
.main-block .categories .accordion-categories .card-body li a:hover {
  color: #3e485a;
}
.main-block .categories .accordion-categories .card-header {
  padding: 0.35rem 0rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: none;
  color: #3e485a;
}
.main-block .categories .accordion-categories .accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.main-block .categories .accordion-categories .accordion > .card:not(:last-of-type) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.main-block .categories .accordion-categories .name-card-header {
  display: inline-block;
  max-width: 180px;
  width: 100%;
}
.main-block .categories .accordion-categories .name-info {
  font-weight: bold;
  color: #3e485a;
  cursor: pointer;
  position: relative;
  padding-right: 20px;
}
.main-block .categories .accordion-categories .name-info a {
  display: inline-block;
  max-width: 100%;
}
.main-block .categories .accordion-categories .name-info a:hover {
  color: #4b6aa0;
}
.main-block .categories .accordion-categories .name-info:after {
  content: url(/public/images/bafc9beb243e312fa43ac5f94c81ea05.svg);
  position: absolute;
  right: 0;
  top: calc((100% - 22px)/2);
}
.main-block .categories .accordion-categories .collapsedStyling a {
  color: #4b6aa0;
}
.main-block .categories .accordion-categories .collapsedStyling:after {
  content: url(/public/images/bafc9beb243e312fa43ac5f94c81ea05.svg);
  float: right;
  margin-left: 5px;
  -ms-transform: rotate(180deg);
  /* IE 9 */
  transform: rotate(180deg);
  /* Standard syntax */
}
.main-block #PieChartMainPage {
  margin-top: 30px;
}
.main-block .pie-chart-main {
  margin: 0px;
  padding: 0 15px;
  padding-top: 0px;
  position: relative;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-block .pie-chart-main_block {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.main-block .pie-chart-main h2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #264576;
  text-transform: none;
  margin-bottom: 0;
}
.main-block .pie-chart-main .grey {
  background-color: #D9D9D9;
}
.main-block .pie-chart-main .green {
  background-color: #80b54b;
}
.main-block .pie-chart-main .yellow {
  background-color: #edc628;
}
.main-block .pie-chart-main .name-table {
  font-size: 18px;
  font-weight: bold;
  color: #647799;
}
.main-block #TableChartMainPage {
  font-size: 15px;
  margin-top: 30px;
}
.main-block #TableChartMainPage .block-color {
  width: 21.71px;
  height: 21.71px;
  margin: 5px auto;
  border: none;
  border-radius: 50%;
}
.main-block #TableChartMainPage .white {
  background-color: #ffffff;
  border: 1px solid #D9D9D9;
}
.main-block #TableChartMainPage .fiolet {
  background-color: #6D61B7;
}
.main-block #TableChartMainPage .blue {
  background-color: #4b6aa0;
}
.main-block #TableChartMainPage .grey {
  background-color: #CDDBEF;
}
.main-block #TableChartMainPage .red {
  background-color: #c45c5c;
}
.main-block #TableChartMainPage .orange {
  background-color: #e69a48;
}
.main-block #TableChartMainPage .number {
  font-weight: bold;
  font-size: 15px;
  color: #264576;
}
.main-block #TableChartMainPage .subcategory {
  color: #6a7fa5;
  font-size: 15px;
}
.main-block #TableChartMainPage .google-visualization-table-tr-head {
  background-color: transparent;
}
.main-block #TableChartMainPage .google-visualization-table-table td {
  border: solid transparent;
  border-width: 0 0 0 0;
  padding: 5px 0.35em;
  font-size: 15px;
}
.main-block #TableChartMainPage .google-visualization-table-table th {
  border: solid transparent;
  border-width: 0 0 0 0;
}
.main-block #TableChartMainPage .google-visualization-table-tr-odd {
  background-color: #fff;
}
.about-img {
  height: 192px;
  width: auto;
  background-size: cover;
  border-radius: 16px;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 30px;
}
.flex-container {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
}
.flex-container .flex-item-left {
  flex: 40%;
}
.flex-container .flex-item-right {
  flex: 60%;
  background-color: #fff;
  padding-right: 15px;
  border-radius: 0 0 16px 0;
  padding-left: 15px;
}
#regions_container {
  background-color: #ffffff;
  box-shadow: 0px 10px 27px 0px rgba(23, 27, 41, 0.9);
  padding: 40px 20px 20px 20px;
  width: 100%;
  max-width: 350px;
}
#regions_container .del {
  background-color: transparent;
  color: #3e485a;
  border-bottom: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: 900;
  font-size: 16px;
}
#regions_container .region_name {
  font-weight: bold;
  font-size: 18px;
  color: #647799;
  margin: 0 15px 30px 15px;
  max-width: 250px;
}
#regions_container .scroll_statistic_table {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
#regions_container .statistic_table_name {
  font-size: 13px;
  color: #647799;
  font-weight: normal;
  margin: 0px 15px;
  max-width: 250px;
}
#regions_container .tr_table .td_table {
  padding: 4px 5px 4px 0;
}
#regions_container .tr_table .td_table span {
  font-weight: 500;
  border-bottom: none;
  color: #3e485a;
}
#regions_container .tr_table .td_table:nth-child(1) {
  max-width: 180px;
}
#regions_container .tr_table .td_table:nth-child(2) {
  max-width: 120px;
  text-align: center;
}
#regions_container .th_table {
  font-size: 13px;
  color: #647799;
  font-weight: normal;
  border-bottom: 1px solid #cdd6e8;
  padding: 10px 15px;
}
#regions_container .bold_td {
  font-weight: 900;
  text-align: center;
}
.modal .modal-body {
  padding: 0 1rem;
  border-radius: 0 0 16px 16px;
}
.modal .modal-header {
  border-bottom: 0;
  border-radius: 16px 16px 0 0;
  padding-right: 40px;
}
.modal .modal-title {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  color: #647799;
}
.modal .modal-content {
  border-radius: 16px;
  background-color: #EBF3FF;
}
.modal .modal-dialog {
  max-width: 300px;
  margin: 230px calc((100vw - 1098px)/2) 50px auto;
  box-shadow: 0px 10px 27px 0px rgba(23, 27, 41, 0.9);
  border-radius: 16px;
}
.modal .close {
  color: #264576;
  background-color: #fff;
  width: 32px;
  height: 32px;
  right: 0px;
  top: 0px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 100px;
  margin: 0;
  font-weight: normal;
  font-size: 31px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  opacity: 1;
}
.modal .modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: 0;
}
.modal .btn-primary {
  background-color: #00a9fd;
  width: 98px;
  height: 33px;
  text-transform: uppercase;
  outline: 0;
  border-radius: 25px;
  border-color: #00a9fd;
  font-weight: bold;
}
.modal .advanced-search .btn-filter {
  background-color: #1E82FA;
}
.modal .advanced-search .btn-filter:hover {
  background-color: #667B9B;
}
.modal table {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.08px;
  color: #191919;
}
.modal-backdrop {
  background: #272b3c;
}
.modal-backdrop.show {
  opacity: 0.8;
}
@media (max-width: 1200px) {
  .modal .modal-dialog {
    max-width: 300px;
    margin: 230px calc((100% - 900px)/2) 50px auto;
  }
}
@media (max-height: 990px) {
  .scroll_statistic_table {
    height: calc(100vh - 200px);
  }
}
@media (max-width: 800px) {
  .flex-item-right,
  .flex-item-left {
    flex: 100%;
  }
}
@media (max-width: 768px) {
  .modal .modal-dialog {
    max-width: 300px;
    margin: 230px calc((100% - 600px)/2) 50px auto;
  }
  .flex-container {
    flex-wrap: wrap;
    height: 100%;
  }
  .main-block .new-objects {
    height: 225px;
  }
  .main-block .pie-chart-main {
    padding-top: 0px;
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .modal .modal-dialog {
    max-width: 300px;
    margin: 30px auto;
    width: 100%;
  }
  .main-block .map-main-page {
    padding: 1.5rem 0;
    margin-bottom: 0px;
  }
  .main-block h2 {
    margin-left: 1.5rem;
  }
  .main-block .federal-district .accordion-federal-district {
    padding: 1.5rem 0;
  }
  .main-block .federal-district .accordion-federal-district .card-header {
    padding: 0.35rem;
  }
}
@media (max-width: 533px) {
  .main-block .categories {
    margin-top: 20px;
  }
}
@media (max-width: 420px) {
  .main-block .main-page-map {
    padding-top: 0!important;
  }
  .main-block .map-main-page {
    padding-top: 16px;
  }
  .main-block .map-main-page .btn-advanced-search {
    background-color: #fdfeff;
    position: absolute;
    right: 5%;
    top: 19px;
    width: 189px;
    height: 32px;
    font-size: 12px;
    font-weight: 400;
  }
  .main-page-map .breadcrumbs {
    padding-left: 16px;
    margin-bottom: 0;
    max-width: 150px;
  }
  .main-page-map .breadcrumbs .map-tab-link {
    font-size: 16px;
  }
}

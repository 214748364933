.searched {
  padding: 10px;
  background-color: #e5ecf4;
  display: flow-root;
}
.searched .tag-searched {
  display: inline-block;
  float: left;
  padding: 5px;
  background-color: transparent;
  border: 1px solid #c1ccde;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.searched .tag-searched:hover {
  color: #4b6aa0;
  background-color: #fff;
}
.searched .btn-clear {
  color: #4b6aa0;
  text-transform: uppercase;
  font-weight: 900;
  display: inline-block;
  float: left;
  padding: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.searched .btn-clear:hover {
  border: 1px solid #c1ccde;
  color: #3e485a;
}

.random-card {
  margin-bottom: 0px;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 16px;
}
.random-card .name-card {
  background-color: #fff;
  padding: 7px;
  text-align: center;
  border-radius: 16px 16px 0 0;
  min-height: 32px;
  height: 100%;
}
.random-card .name-card h3 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #264576;
  margin-bottom: 0;
  margin-top: 0;
}
.random-card .block-img {
  height: 255px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.random-card .text-card {
  padding: 0.875rem 0.875rem 0 0.875rem;
}
.random-card .text-card h2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #264576;
  text-transform: none;
}
.random-card .text-card .address {
  margin-left: 22px;
  font-size: 12px;
  font-weight: normal;
  vertical-align: super;
  line-height: 1.2;
  display: inherit;
  margin-bottom: 7px;
}
.random-card .text-card img {
  float: left;
}
.random-card .text-card p {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-clamp: 4;
  box-orient: vertical;
}
.random-card h2 {
  font-size: 18px;
  font-weight: bold;
  color: #647799;
  margin-bottom: 15px;
}
.random-card .share-block {
  margin-top: 0;
  padding: 0 0.875rem 0.875rem 0.875rem;
}
.random-card .share-block .float-left {
  margin-right: 10px;
}
.random-card .share-block .float-right {
  margin-left: 15px;
}
.random-card .share-block img {
  cursor: pointer;
  height: 18px;
  width: auto;
}

* {
  margin: 0;
  padding: 0;
}
body,
html {
  height: 100%;
  margin: 0;
}
body {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  color: #264576;
  background-color: #EBF3FF;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
a {
  background-color: transparent;
}
a,
a:active,
a:hover,
a:focus {
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #3e485a;
}
a::-moz-focus-inner {
  border: 0;
}
.main-content {
  min-height: 500px;
  height: auto;
}
#special {
  max-height: 440px;
  overflow: auto;
}
.special-line-height-1 .random-card-list *,
#special .special-line-height button[value='1'] .random-card-list {
  line-height: 1.5 !important;
}
.special-color-1 * .container,
#special .special-color button[value='1'] .container,
.special-color-1 * .container-lg,
#special .special-color button[value='1'] .container-lg,
.special-color-1 * .container-md,
#special .special-color button[value='1'] .container-md,
.special-color-1 * .container-sm,
#special .special-color button[value='1'] .container-sm,
.special-color-1 * .container-xl,
#special .special-color button[value='1'] .container-xl {
  max-width: 1400px;
}
.special-color-1 * h1,
#special .special-color button[value='1'] h1 {
  font-size: 2.5rem !important;
}
.special-color-1 * .main-block .categories .accordion-categories .name-info,
#special .special-color button[value='1'] .main-block .categories .accordion-categories .name-info {
  padding: 0 40px 0 30px;
}
.special-color-1 * .main-block .categories .accordion-categories .name-info:after,
#special .special-color button[value='1'] .main-block .categories .accordion-categories .name-info:after {
  right: 20px;
}
.special-color-1 * .btnPlay,
#special .special-color button[value='1'] .btnPlay {
  position: relative;
  border: none;
}
.special-color-1 * .btnPlay::after,
#special .special-color button[value='1'] .btnPlay::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 40px solid #000;
  border-bottom: 20px solid transparent;
  position: absolute;
  left: -2px;
  top: -7px;
}
.special-color-1 * .main-block .map-main-page .btn-advanced-search,
#special .special-color button[value='1'] .main-block .map-main-page .btn-advanced-search {
  right: 15px;
}
.special-color-1 * .navbar-expand-md .navbar-nav,
#special .special-color button[value='1'] .navbar-expand-md .navbar-nav {
  flex-wrap: wrap;
}
.special-color-1 * .google-visualization-tooltip text,
#special .special-color button[value='1'] .google-visualization-tooltip text {
  font-size: 1rem !important;
}
.special-color-1 * .list-regions,
#special .special-color button[value='1'] .list-regions {
  display: flex;
  flex-direction: column;
}
.special-color-1 * .list-regions .single-record,
#special .special-color button[value='1'] .list-regions .single-record {
  width: 100%;
}
.special-color-1 * #TableChart .google-visualization-table-table td,
#special .special-color button[value='1'] #TableChart .google-visualization-table-table td {
  padding: 3px;
}
.special-color-1 * .district-select .custom-select,
#special .special-color button[value='1'] .district-select .custom-select {
  max-width: 100%;
}
.special-color-1 * .block-document .download-btn,
#special .special-color button[value='1'] .block-document .download-btn {
  max-width: 100%;
  margin-right: 0;
}
.special-color-1 * .block-document .document-name,
#special .special-color button[value='1'] .block-document .document-name {
  width: 72%;
}
.special-color-1 * .council-block .my-masonry-grid,
#special .special-color button[value='1'] .council-block .my-masonry-grid {
  flex-direction: column;
}
.special-color-1 * .council-block .my-masonry-grid .my-masonry-grid_column,
#special .special-color button[value='1'] .council-block .my-masonry-grid .my-masonry-grid_column {
  width: 100% !important;
}
.special-color-1 * .council-block .block-member,
#special .special-color button[value='1'] .council-block .block-member {
  width: 100%;
}
.special-color-1 * .right-col-card .read-more,
#special .special-color button[value='1'] .right-col-card .read-more {
  max-width: 240px;
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1450px;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav {
    flex-wrap: wrap;
  }
  .special-color-1 *,
  #special .special-color button[value='1'] .main-block .map-main-page .btn-advanced-search {
    right: 0;
  }
}

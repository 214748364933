.map-container {
  margin-bottom: 40px;
}
.table-region-statistic {
  font-size: 9.35px;
  text-transform: uppercase;
  text-align: center;
}
.table-region-statistic th {
  font-weight: normal;
}
.table-region-statistic thead th,
.table-region-statistic th,
.table-region-statistic td {
  border: 0;
}
.table-region-statistic .quantity {
  font-size: 24px;
  background-color: #e7ebf3;
}
ymaps .balloon-map {
  font-family: 'Myriad Pro';
  vertical-align: top;
}
ymaps .balloon-map .balloon-block-img {
  padding: 0 10px 0 0;
  vertical-align: top;
}

h1 {
  font-size: 26px;
  color: #3e485a;
  margin-bottom: 25px;
}
h2 {
  font-size: 18px;
  color: #647799;
  font-weight: bold;
}
.left-nav-card .nav-link {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #264576;
  background: #EBF3FF;
  box-shadow: 0px 4px 7px rgba(100, 177, 252, 0.3);
  border-radius: 20px;
  margin-bottom: 8px;
  height: 40px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
.left-nav-card .nav-link.active {
  background-color: #fff !important;
  border-radius: 25px;
}
.right-col-card .sort-objects .col-md-12:not(:last-child) .block-document {
  border-bottom: 1px solid #cfd7e5;
}
.right-col-card .sort-objects .block-sort {
  line-height: 45px;
  padding: 0 10px;
}
.right-col-card .sort-objects .block-sort img {
  display: inline-block;
  width: 45px;
  float: right;
  cursor: pointer;
}
.right-col-card .sort-objects .block-sort .total {
  vertical-align: middle;
  margin-right: 20px;
  display: inline-block;
}
.right-col-card .sort-objects .clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.right-col-card .sort-objects .random-card-list {
  border-bottom: 0;
  font-family: 'Roboto';
  padding: 10px 10px 15px 10px;
  border: 1px solid transparent;
  border-radius: 6px;
}
.right-col-card .sort-objects .random-card-list:hover {
  border: 1px solid #d8deec;
  background-image: -moz-linear-gradient(90deg, rgba(231, 235, 244) 0%, rgba(255, 255, 255) 44%);
  background-image: -webkit-linear-gradient(90deg, rgba(231, 235, 244) 0%, rgba(255, 255, 255) 44%);
  background-image: -ms-linear-gradient(90deg, rgba(231, 235, 244) 0%, rgba(255, 255, 255) 44%);
}
.right-col-card .sort-objects .random-card-list .header-card {
  padding-top: 0;
}
.right-col-card .sort-objects .random-card-list .header-card .card-name {
  padding: 0;
  display: inline-block;
  float: left;
  color: #4b6aa0;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.429;
}
.right-col-card .sort-objects .random-card-list .header-card .card-category {
  background-color: #e7ebf3;
  padding: 5px 10px;
  font-size: 13px;
  font-family: 'Myriad Pro';
  color: #3e485a;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 0;
  margin-left: 0px;
}
.right-col-card .sort-objects .random-card-list .card-text {
  font-size: 13px;
  margin: 15px 0;
}
.right-col-card .sort-objects .random-card-list .address {
  font-size: 12px;
  font-weight: normal;
  vertical-align: super;
  display: block;
  float: none;
}
.right-col-card .sort-objects .random-card-list .address img {
  margin-right: 10px;
}
.right-col-card .sort-objects .share-block {
  display: block;
  float: none;
  margin-top: 20px;
}
.right-col-card .sort-objects .share-block .float-left {
  margin-right: 10px;
}
.right-col-card .sort-objects .share-block .float-right {
  margin-left: 15px;
}
.right-col-card .sort-objects .share-block img {
  cursor: pointer;
  height: 15px;
  width: auto;
}
.right-col-card .sort-objects .containerRandomCard {
  width: 30%;
  margin: 0 3% 30px 0;
  border: 1px solid transparent;
  background-color: #fff;
  border-radius: 16px;
}
.right-col-card .sort-objects .containerRandomCard .random-card {
  margin-bottom: 0px;
  border: none;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.right-col-card .sort-objects .containerRandomCard .random-card .name-card {
  background-color: #e7ebf3;
  padding: 7px;
  text-align: center;
  max-height: 70px;
}
.right-col-card .sort-objects .containerRandomCard .random-card .name-card h3 {
  font-size: 14px;
  font-family: 'Myriad Pro';
  color: #3e485a;
  line-height: 1.429;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 0;
  margin-top: 0;
}
.right-col-card .sort-objects .containerRandomCard .random-card .block-img {
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.right-col-card .sort-objects .containerRandomCard .random-card .text-card {
  padding: 0.875rem 0.875rem 0 0.875rem;
}
.right-col-card .sort-objects .containerRandomCard .random-card .text-card h2 {
  color: #4b6aa0;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
  line-height: 1.2;
}
.right-col-card .sort-objects .containerRandomCard .random-card .text-card .address {
  margin-left: 22px;
  font-size: 12px;
  font-weight: normal;
  vertical-align: super;
  line-height: 1.2;
  display: inherit;
  margin-bottom: 7px;
}
.right-col-card .sort-objects .containerRandomCard .random-card .text-card img {
  float: left;
}
.right-col-card .sort-objects .containerRandomCard .random-card .text-card p {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-clamp: 4;
  box-orient: vertical;
}
.right-col-card .sort-objects .containerRandomCard .random-card h2 {
  font-size: 18px;
  font-weight: bold;
  color: #647799;
  margin-bottom: 15px;
}
.right-col-card .sort-objects .containerRandomCard .random-card .share-block {
  margin-top: 0;
  padding: 0.875rem;
}
.right-col-card .sort-objects .containerRandomCard .random-card .share-block .float-left {
  margin-right: 10px;
}
.right-col-card .sort-objects .containerRandomCard .random-card .share-block .float-right {
  margin-left: 15px;
}
.right-col-card .sort-objects .containerRandomCard .random-card .share-block img {
  cursor: pointer;
  height: 24px;
  width: auto;
}
.right-col-card .sort-objects .containerRandomCard:hover {
  -webkit-box-shadow: 5px 2px 33px 3px rgba(55, 106, 163, 0.51);
  -moz-box-shadow: 5px 2px 33px 3px rgba(55, 106, 163, 0.51);
  box-shadow: 5px 2px 33px 3px rgba(55, 106, 163, 0.51);
}
.right-col-card .pagination {
  justify-content: center;
  gap: 16px;
}
.right-col-card .sort-pagination {
  margin: 50px auto;
  width: 75%;
}
.right-col-card .sort-pagination .page-item {
  outline: 0;
}
.right-col-card .sort-pagination .page-item:first-child .page-link,
.right-col-card .sort-pagination .page-item:last-child .page-link {
  width: 60px;
  border-radius: 20px;
  border: 1px solid rgba(25, 25, 25, 0.16);
  background-color: transparent;
}
.right-col-card .sort-pagination .page-link {
  background-color: rgba(25, 25, 25, 0.08);
  color: #8791AA;
  border: none;
  outline: 0;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.right-col-card .sort-pagination .page-item.active .page-link {
  z-index: 3;
  color: #264576;
  background-color: #fff;
  box-shadow: 0 4px 7px rgba(100, 177, 252, 0.3);
  border-radius: 50%;
}
.left-block-search {
  margin: 5px 0 45px 0;
}
.left-block-search .block-search {
  padding-top: 0;
}
.left-block-search .form-search {
  margin-bottom: 10px;
}
.left-block-search .form-search input {
  width: 80%;
  padding-left: 0.75rem;
}
.left-block-search .form-control {
  border: 1px solid #3452c1;
  border-radius: 3.25rem 0 0 3.25rem;
  border-right: 1px solid transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-right: 0;
}
.left-block-search .btn-light {
  background-color: #ffffff;
  border-color: #3452c1;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0 3.25rem 3.25rem 0;
  border-left: 1px solid transparent;
}
.left-block-search .container-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.left-block-search .container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.left-block-search .container-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: solid 1px #3452c1;
}
.left-block-search .container-checkbox:hover input ~ .checkmark {
  background-color: #fff;
}
.left-block-search .container-checkbox input:checked ~ .checkmark {
  background-color: #647799;
}
.left-block-search .container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.left-block-search .container-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.left-block-search .container-checkbox .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0px 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.advanced-search .block-attribute-value {
  margin: 10px 0 30px 0;
}
.advanced-search .block-attribute-value .input-advanced-search .custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgOCA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC43NTk3NjYgMC4zNzU0NDVMMS4wMDg5MyAwLjAzNzU5NzdMNy43NjIyNiAwLjAzNzU5NzdMNy45OTkzNiAwLjM2MzM3OUw0LjYwNDU5IDMuNjU3MzlINC4xMDU2NkwwLjc1OTc2NiAwLjM3NTQ0NVoiIGZpbGw9IiMyNjQ1NzYiLz4KPC9zdmc+Cg==") no-repeat right 0.75rem center / 8px 4px;
  border: 1px solid #3452c1;
  border-radius: 3.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.advanced-search .btn-filter {
  width: 100px;
  margin: 20px auto;
  color: #fff;
  background-color: #4b6aa0;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
}
.flexContainerCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .right-block-search .form-search input {
    width: 80%;
  }
  .right-col-card .sort-objects .containerRandomCard {
    width: 45%;
    margin-bottom: 30px;
    border: 1px solid #d8deec;
  }
  .sort-pagination {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 820px) {
  .right-col-card .sort-objects .flexContainerCards {
    margin-right: 0;
    margin-left: 0;
    justify-content: space-between;
    gap: 16px;
  }
  .right-col-card .sort-objects .containerRandomCard {
    margin: 0;
  }
}
@media (max-width: 459px) {
  .right-col-card {
    padding: 25px 0!important;
  }
  .right-col-card .sort-objects .block-sort {
    margin: 20px 0;
  }
  .right-col-card .sort-objects .containerRandomCard {
    width: 100%;
    border: 1px solid #d8deec;
    margin: 0;
  }
  .right-col-card .sort-objects .flexContainerCards {
    margin-left: 0;
    margin-right: 0;
    gap: 16px;
  }
  .advanced-search .search-lens img {
    right: 25px!important;
  }
}
@media (max-width: 320px) {
  .right-col-card .sort-objects .containerRandomCard {
    width: 90%;
    margin: 0 auto 30px auto;
    border: 1px solid #d8deec;
  }
}

.council-block {
  /* Style your items */
}
.council-block .nav-pills .nav-link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #264576;
  background: #ebf3ff;
  box-shadow: 0px 4px 7px rgba(100, 177, 252, 0.3);
  border-radius: 20px;
  margin-bottom: 8px;
  height: 40px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
.council-block .nav-pills .nav-link.active {
  background-color: #fff !important;
  border-radius: 25px;
}
.council-block h1 {
  color: #3e485a;
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 25px;
}
.council-block h3 {
  font-size: 20px;
  color: #3e485a;
  font-weight: normal;
  margin: 0;
}
.council-block h4 {
  font-size: 9.35px;
  font-weight: normal;
  padding: 5px 15px;
  color: #3e485a;
  background-color: #e7ebf3;
  border: 1px solid #cbd8f1;
  width: fit-content;
  text-transform: uppercase;
  min-width: 100px;
}
.council-block .block-member {
  border-bottom: 2px solid #cfd7e5;
  margin: 10px;
  padding: 20px 0;
}
.council-block .position-right-col {
  font-size: 12px;
  font-weight: normal;
}
.council-block .tab-content {
  margin-bottom: 50px;
}
.council-block .my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto;
}
.council-block .my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;
}
.council-block .my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .council-block .nav-pills .nav-link {
    font-size: 13px;
  }
}
